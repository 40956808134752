import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Divider, useTheme } from "@mui/material";
import SlideIn from "./SlideIn";
import { SelectVehicle } from "../SelectVehicle";
import { LockClosedIcon } from "@heroicons/react/solid";
import config from "../../config";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SlideInGuarantor({
  setOpen,
  open,
  next,
  vehicles,
  addVehicleToList,
  user,
  setLoading,
  loading,
  setHMessage,
}) {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [work, setWork] = useState("");
  const [message, setMessage] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [phoneValidated, setPhoneValidated] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
    if (!!firstname && !!lastname && !!phoneValidated && !!emailValidated && !!work) {
      setValidated(true);
    }
  }, [open, firstname, lastname, phoneValidated, emailValidated, work]);

  const changeEmail = (e) => {
    var email = e.target.value;
    const validated = validateEmail(email);
    if (!!validated) {
      setEmail(email);
    }
  };

  const validateEmail = (email) => {
    setEmailValidated(false);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!!reg.test(email)) {
      setEmailValidated(true);
      setMessage("");
      return true;
    }

    setMessage("Enter a valid email address");
    return false;
  };

  const changeFName = (e) => {
    var firstname = e.target.value;
    setFirstname(firstname);
  };

  const changeLName = (e) => {
    let lastname = e.target.value;
    setLastname(lastname);
  };

  const changeTel = (e) => {
    let phone = e.target.value;
    const validated = validatePhone(phone);
    if (!!validated) setPhone(phone);
  };

  const validatePhone = (phone) => {
    let phone_val;
    if (phone.length != 11) {
      phone_val = false;
    } else {
      if (/^\d{10}/.test(phone)) {
        phone_val = true;
      } else {
        phone_val = false;
      }
    }

    if (!phone_val) {
      setPhoneValidated(false);
      setMessage("Enter a valid phone number");
      return false;
    } else {
      setPhoneValidated(true);
      setMessage("");
      return true;
    }
  };

  const changeWork = (e) => {
    var work = e.target.value;
    setWork(work);
  };

  const updateGuarantor = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(config.URL + "user/guarantor/" + user?._id, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + user?.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstname,
        lastname,
        phone,
        email,
        work,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          setMessage({ status: "success", text: "Guarantor data saved successfully" });
          localStorage.setItem("@guarantor", true);
          localStorage.setItem("@user", JSON.stringify(responseJson.data));
          setOpen(false);
          window.location.reload()

        } else {
          setHMessage({ status: "error", text: responseJson.msg });
        }
      })
      .catch((error) => {
        setHMessage({ status: "error", text: "Network Unavailabe!" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SlideIn
      open={open}
      setOpen={setOpen}
      next={next}
      title={"Guarantor's Information"}
    >
      <form
        className="mt-8 space-y-6"
        action="#"
        method="POST"
        onSubmit={updateGuarantor}
      >
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="first-name" className="sr-only">
              First Name
            </label>
            <input
              id="first-name"
              name="firstname"
              type="text"
              autoComplete="name"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm h-12"
              placeholder="First Name"
              onChange={changeFName}
            />
          </div>
          <div>
            <label htmlFor="last-name" className="sr-only">
              Last Name
            </label>
            <input
              id="last-name"
              name="lastname"
              type="text"
              autoComplete="name"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm h-12"
              placeholder="Last Name"
              onChange={changeLName}
            />
          </div>
          <div>
            <label htmlFor="company" className="sr-only">
              Company they work in
            </label>
            <input
              id="company"
              name="company"
              type="text"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm h-12"
              placeholder="Company they work in"
              onChange={changeWork}
            />
          </div>
          <div>
            <label htmlFor="phone-number" className="sr-only">
              Phone Number
            </label>
            <input
              id="phone-number"
              name="phone"
              type="text"
              autoComplete="number"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm h-12"
              placeholder="Phone Number"
              onChange={changeTel}
            />
          </div>
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email Address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm h-12"
              placeholder="Email Address"
              onChange={changeEmail}
            />
          </div>

          {/* <span className="text-xs text-red-500">{message}</span> */}
          <div className="h-12"></div>

          <div>
            <LoadingButton
              loading={loading}
              disabled={!validated}
              variant="outlined"
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save 
            </LoadingButton>
          </div>
        </div>
      </form>
    </SlideIn>
  );
}
