import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";

export default function BasicCard({
  title,
  setOpen,
  width = "100%",
  color = "whitesmoke",
  bgColor = "#333",
  Icon,
  openable,
}) {
  return (
    <Card
      onClick={() => setOpen(openable)}
      sx={{ width: width, height: 250, backgroundColor: bgColor }}
      className="flex justify-center items-center"
    >
      <CardContent className="flex flex-col justify-center items-center">
        <Icon style={{ color: "whitesmoke", fontSize: 50 }} />
        <h2
          className="mt-6 text-l tracking-tight  text-gray-300"
          style={{ color: color }}
        >
          {title}
        </h2>
      </CardContent>
    </Card>
  );
}
