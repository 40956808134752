import React, { useEffect, useState } from "react";
import { Alert, Card } from "@mui/material";
import { LockClosedIcon } from "@heroicons/react/solid";
import "../App";

import logo from "../logo.svg";
import Header from "../components/Header";
import EmailPassword from "../components/Home/EmailPassword";
import Personal from "../components/Home/Personal";
import Home from "./Home";
import Documents from "./Documents";
import Loader from "../components/Loader";
import AuthDocuments from "./AuthDocuments";

function Dashboard({
  authenticated,
  setAuthenticated,
  user,
  setUser,
  loading,
  setLoading,
}) {
  const [screen, setSetScreen] = useState(1);
  //   const [authenticated, setAuthenticated] = useState(false);
  //   const [user, setUser] = useState(null);
  //   const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ status: "", text: "" });

  //   useEffect(() => {
  //     const user = JSON.parse(localStorage.getItem("@user"));
  //     console.log("USER >>> ", user);
  //     if (!!user) {
  //       setUser(user);
  //       setAuthenticated(true);
  //     }
  //   }, []);

  return (
    <div className="flex flex-row h-screen">
      <section className="h-screen flex-auto justify-center items-center">
        {message.text && (
          <Alert className="m-5" severity={message.status}>
            {message.text}
          </Alert>
        )}
          <AuthDocuments
            user={user}
            setLoading={setLoading}
            loading={loading}
            setMessage={setMessage}
            inDashboard={true}
            authenticated={authenticated}
          />
        <Loader loading={loading} />
      </section>
    </div>
  );
}

export default Dashboard;
