import { Chip } from "@mui/material";
import React, { Component } from "react";
import TwoWheelerOutlinedIcon from "@mui/icons-material/TwoWheelerOutlined";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BadgeIcon from "@mui/icons-material/Badge";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

const defaultProps = {
  selected: false,
  activecolor: "blue",
  inactivecolor: "red",
  icon: "walking",
  word: "walking",
};

export function SelectVehicle({ vehicle, isSelected, addVehicleToList }) {
  const handleClick = () => {};

  const handleDelete = () => {};

  const getIcon = (icon) => {
    switch (icon) {
      case "all":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("all")}
            variant={!isSelected ? "outlined" : ""}
            icon={<OpenInFullIcon />}
            label="All"
          />
        );
      case "walking":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("walking")}
            variant={!isSelected ? "outlined" : ""}
            icon={<DirectionsWalkIcon />}
            label="Walking"
          />
        );
      case "bicycle":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("bicycle")}
            variant={!isSelected ? "outlined" : ""}
            icon={<PedalBikeIcon />}
            label="Bicycle"
          />
        );
      case "car":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("car")}
            variant={!isSelected ? "outlined" : ""}
            icon={<LocalTaxiIcon />}
            label="Car"
          />
        );
      case "big car":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("big car")}
            variant={!isSelected ? "outlined" : ""}
            icon={<AirportShuttleIcon />}
            label="Big car"
          />
        );
      case "cycle":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("cycle")}
            variant={!isSelected ? "outlined" : ""}
            icon={<TwoWheelerOutlinedIcon />}
            label="Cycle"
          />
        );
      case "truck":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("truck")}
            variant={!isSelected ? "outlined" : ""}
            icon={<LocalShippingIcon />}
            label="Truck"
          />
        );
      case "permit":
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("permit")}
            variant={!isSelected ? "outlined" : ""}
            icon={<BadgeIcon />}
            label="Permit"
          />
        );
      default:
        return (
          <Chip
            style={{minWidth: 100, minHeight: 80}}
            color={"warning"}
            clickable
            onClick={() => addVehicleToList("all")}
            variant={!isSelected ? "outlined" : ""}
            icon={<OpenInFullIcon />}
            label="All"
          />
        );
    }
  };

  return <div>{getIcon(vehicle)}</div>;
}
