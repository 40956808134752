import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { LockClosedIcon } from "@heroicons/react/solid";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../App";

import logo from "../logo.svg";
import Header from "../components/Header";
import EmailPassword from "../components/Home/EmailPassword";
import Personal from "../components/Home/Personal";
import Loader from "../components/Loader";
import config from "../config";

function Home({ setAuthenticated, setLoading, setHMessage }) {
  const [screen, setSetScreen] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [personalValidated, setPersonalValidated] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
    if (!!emailValidated && !!personalValidated) {
      setValidated(true);
    }
  }, [emailValidated, personalValidated]);

  const register = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(config.URL + "user/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first: firstname,
        last: lastname,
        tel: phone,
        type: "driver",
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let status = responseJson.status;
        setMessage("Successful");
        if (status) {
          localStorage.setItem("@user", JSON.stringify(responseJson));
          setHMessage({ status: "success", text: responseJson.msg });
          setAuthenticated(true);
          window.location.reload()
        } else {
          setHMessage({ status: "error", text: responseJson.msg });
        }
      })
      .catch((error) => {
        setHMessage({ status: "error", text: error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://wedeliva.ng/img/Deliva.png"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
            Getting Started
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Register a dispatcher account in 3 simple steps
          </p>
        </div>
        <form
          className="mt-8 space-y-6"
          action="#"
          method="POST"
          onSubmit={register}
        >
          {/* {screen <= 1 && ( */}
            <EmailPassword
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              setValidated={setEmailValidated}
              setSetScreen={setSetScreen}
            />
          {/* )} */}
          {/* {screen == 1 && ( */}
            <Personal
              firstname={firstname}
              lastname={lastname}
              phone={phone}
              setFirstname={setFirstname}
              setLastname={setLastname}
              setPhone={setPhone}
              setValidated={setPersonalValidated}
              setSetScreen={setSetScreen}
            />
          {/* )} */}

          <div className="flex items-center justify-between">
            <div className="flex items-center" />
            <div className="text-sm">
              <Link
                to="/login"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {" "}
                Login Instead?{" "}
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={!validated}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Home;
