import React, { useEffect, useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";

function EmailPassword({
  email,
  password,
  setEmail,
  setPassword,
  setValidated,
  setSetScreen,
}) {
  const [message, setMessage] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
    if (!!emailValidated && !!passwordValidated) {
      setValidated(true);
      setSetScreen(1);
    }
  }, [emailValidated, passwordValidated]);

  const changeEmail = (e) => {
    var email = e.target.value;
    const validated = validateEmail(email);
    if (!!validated) {
      setEmail(email);
    }
  };

  const changePassword = (e) => {
    var password = e.target.value;
    const validated = validatePassword(password);
    if (!!validated) {
      setPassword(password);
    }
  };

  const validateEmail = (email) => {
    setEmailValidated(false);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!!reg.test(email)) {
      setEmailValidated(true);
      setMessage("");
      return true;
    }

    setMessage("Enter a valid email address");
    return false;
  };

  const validatePassword = (password) => {
    setPasswordValidated(false);
    if (password.length >= 6) {
      setPasswordValidated(true);
      setMessage("");
      return true;
    }

    setMessage("Password should be at least 6 characters");
    return false;
  };

  return (
    <div className="mt-8 space-y-6">
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
            onChange={changeEmail}
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Password"
            onChange={changePassword}
          />
        </div>
      </div>
      <span className="text-xs text-red-500">{message}</span>
      <div className="h-1"></div>
    </div>
  );
}

export default EmailPassword;
