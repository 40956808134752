import React, { useEffect, useState } from "react";
import { Alert, Card } from "@mui/material";
import { LockClosedIcon } from "@heroicons/react/solid";
import "../App";

import EmailPassword from "../components/Home/EmailPassword";
import Loader from "../components/Loader";
import config from "../config";
import {
    Link,
    useNavigate,
  } from "react-router-dom";

function Login({
  authenticated,
  setAuthenticated,
  user,
  setUser,
  loading,
  setLoading,
}) {

    let navigate = useNavigate();

  const [screen, setSetScreen] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
    if (!!emailValidated && !!password) {
      setValidated(true);
    }
  }, [emailValidated, password]);

  const login = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(config.URL + "user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let status = responseJson.status;
        setMessage("Successful");
        if (status) {
          setAuthenticated(true);
          localStorage.setItem("@user", JSON.stringify(responseJson));
          setMessage({ status: "success", text: responseJson.msg });
        //   navigate("/dashboard")
          window.location.assign("/dashboard")
        } else {
          setMessage({ status: "error", text: responseJson.msg });
        }
      })
      .catch((error) => {
        setMessage({ status: "error", text: error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-row h-screen">
      <section className="h-screen flex-auto justify-center items-center">
        {message.text && (
          <Alert className="m-5" severity={message.status}>
            {message.text}
          </Alert>
        )}

        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src="https://wedeliva.ng/img/Deliva.png"
                alt="Workflow"
              />
              <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                Welcome Back!
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Login to your dispatcher account
              </p>
            </div>
            <form
              className="mt-8 space-y-6"
              action="#"
              method="POST"
              onSubmit={login}
            >
              <EmailPassword
                email={email}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
                setValidated={setEmailValidated}
                setSetScreen={setSetScreen}
              />

              <div className="flex items-center justify-between">
                <div className="flex items-center" />
                <div className="text-sm">
                  <Link
                    to="/"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {" "}
                    Register Instead?{" "}
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={!validated}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        <Loader loading={loading} />
      </section>
    </div>
  );
}

export default Login;
