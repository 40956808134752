import React, { useEffect, useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";

function Personal({
  firstname,
  lastname,
  phone,
  setFirstname,
  setLastname,
  setPhone,
  setValidated,
}) {
  const [message, setMessage] = useState("");
  const [phoneValidated, setPhoneValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
    if (!!firstname && !!lastname && !!phoneValidated) {
      setValidated(true);
    }
  }, [firstname, lastname, phoneValidated]);

  const changeFName = (e) => {
    var firstname = e.target.value;
    setFirstname(firstname);
  };

  const changeLName = (e) => {
    let lastname = e.target.value;
    setLastname(lastname);
  };

  const changeTel = (e) => {
    let phone = e.target.value;
    const validated = validatePhone(phone);
    if (!!validated) setPhone(phone);
  };

  const validatePhone = (phone) => {
    let phone_val;
    if (phone.length != 11) {
      phone_val = false;
    } else {
      if (/^\d{10}/.test(phone)) {
        phone_val = true;
      } else {
        phone_val = false;
      }
    }

    if (!phone_val) {
      setPhoneValidated(false);
      setMessage("Enter a valid phone number");
      return false;
    } else {
      setPhoneValidated(true);
      setMessage("");
      return true;
    }
  };

  return (
    <div className="mt-8 space-y-6">
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="first-name" className="sr-only">
            First name
          </label>
          <input
            id="first-name"
            name="firstname"
            type="text"
            autoComplete="name"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="First Name"
            onChange={changeFName}
          />
        </div>
        <div>
          <label htmlFor="last-name" className="sr-only">
            Last name
          </label>
          <input
            id="last-name"
            name="lastname"
            type="text"
            autoComplete="name"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Last Name"
            onChange={changeLName}
          />
        </div>
        <div>
          <label htmlFor="phone-number" className="sr-only">
            Phone Number
          </label>
          <input
            id="phone-number"
            name="phone"
            type="number"
            autoComplete="number"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Phone Number"
            onChange={changeTel}
          />
        </div>
      </div>
      <span className="text-xs text-red-500">{message}</span>
      <div className="h-3"></div>
    </div>
  );
}

export default Personal;
