import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Onboarding from "./pages/Onboarding";

export default function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("@user"));
    console.log("USER >>> ", user);
    if (!!user) {
      setUser(user);
      setAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Onboarding
                authenticated={authenticated}
                setAuthenticated={setAuthenticated}
                user={user}
                setUser={setUser}
                setLoading={setLoading}
                loading={loading}
              />
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Login
                authenticated={authenticated}
                setAuthenticated={setAuthenticated}
                user={user}
                setUser={setUser}
                setLoading={setLoading}
                loading={loading}
              />
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Dashboard
                authenticated={authenticated}
                setAuthenticated={setAuthenticated}
                user={user}
                setUser={setUser}
                setLoading={setLoading}
                loading={loading}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}
