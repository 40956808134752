import React, { useEffect, useState } from "react";
import { Alert, Card } from "@mui/material";
import { LockClosedIcon } from "@heroicons/react/solid";
import "../App";

import logo from "../logo.svg";
import Header from "../components/Header";
import EmailPassword from "../components/Home/EmailPassword";
import Personal from "../components/Home/Personal";
import Home from "./Home";
import Documents from "./Documents";
import Loader from "../components/Loader";

function Onboarding({
  authenticated,
  setAuthenticated,
  user,
  setUser,
  loading,
  setLoading,
}) {
  const [screen, setSetScreen] = useState(1);
  //   const [authenticated, setAuthenticated] = useState(false);
  //   const [user, setUser] = useState(null);
  //   const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ status: "", text: "" });

  //   useEffect(() => {
  //     const user = JSON.parse(localStorage.getItem("@user"));
  //     console.log("USER >>> ", user);
  //     if (!!user) {
  //       setUser(user);
  //       setAuthenticated(true);
  //     }
  //   }, []);

  return (
    <div className="flex flex-row h-screen">
      <section
        className="flex-initial hidden lg:block"
        style={{
          backgroundImage: "url('https://wedeliva.ng/img/bg.jpg')",
          backgroundSize: "cover",
        }}
      >
        {/* <Header user={user} /> */}
        <div className="container w-3/5 flex flex-col justify-center h-full ">
          <h1 className="text-white text-2xl mb-5">
            WeDeliva
          </h1>
          <h1 className="text-white text-6xl mb-10">
            Reach the ends of the earth.
          </h1>
          <p className="text-white mb-10 text-xl">
            {" "}
            Deliva helps you send a package anywhere, anytime with verified
            individuals travelling your route.
          </p>
          <div className="dl-btns mb-25 wow fadeIn mt-5">
            <a
              href="#"
              target="_blank"
              className="btn btn-app btn-border apple wow fadeInUp"
              data-wow-delay="0.6s"
              //   style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;"
            >
              <span>Get it on</span>
              <span>App store</span>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.newdelivaapp"
              target="_blank"
              className="btn btn-app btn-border android wow fadeInUp"
              data-wow-delay="0.7s"
              //   style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;"
            >
              <span>Get it on</span>
              <span>Play store</span>
            </a>
          </div>
        </div>
      </section>
      <section className="h-screen flex-auto justify-center items-center">
        {message.text && (
          <Alert className="m-5" severity={message.status}>
            {message.text}
          </Alert>
        )}

        {!authenticated ? (
          <Home
            setAuthenticated={setAuthenticated}
            user={user}
            setLoading={setLoading}
            loading={loading}
            setHMessage={setMessage}
          />
        ) : (
          <Documents
            user={user}
            setLoading={setLoading}
            loading={loading}
            setMessage={setMessage}
            authenticated={authenticated}
          />
        )}
        <Loader loading={loading} />
      </section>
    </div>
  );
}

export default Onboarding;
