export default {
    "IMAGE": "https://api-dev.wedeliva.ng/",
    "URL": "https://api-dev.wedeliva.ng/v1/",
    "WEB": "https://api-dev.wedeliva.ng/delivaweb/",

    // "IMAGE": "http://localhost:3001/",
    // "URL": "http://localhost:3001/v1/",
    // "WEB": "http://localhost:3001/delivaweb/",
    
    "ably": "C_eQoA.NzIAuA:wSAhaykRniuqohff",
    "paystack_secret": "sk_test_1d24952b191def72634f3b5f87fd4908cda305c1"
}
  