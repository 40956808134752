import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Divider, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SlideIn({
  setOpen,
  open,
  next,
  setNext,
  title,
  validated,
  uploadDocuments,
  loading,
  children,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        fullScreen={fullScreen}
      >
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <DialogContent style={{ minHeight: 165, minWidth: 400 }}>
          {children}
        </DialogContent>
        {next != "guarantor" && (
          <DialogActions>
            <Button
              onClick={() => {
                next === "vehicle" && setOpen(false);
                next === "documents" && setNext("vehicle");
                next === "idcard" && setNext("documents");
                next === "permit" && setNext("documents");
              }}
            >
              {next === "vehicle" && "Cancel"}
              {next != "vehicle" && "Back"}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!validated}
              onClick={() => {
                //   setOpen(false)
                next != "documents" ? setNext("documents") : uploadDocuments();
              }}
            >
              {next != "documents" && "Next"}
              {next === "documents" && "Upload"}
              {/* {next === "permit" && "Upload"} */}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
