import React, { useEffect, useState } from "react";
import { Card, Chip } from "@mui/material";
import { LockClosedIcon } from "@heroicons/react/solid";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import TwoWheelerOutlinedIcon from "@mui/icons-material/TwoWheelerOutlined";
import "../App";

import logo from "../logo.svg";
import Header from "../components/Header";
import EmailPassword from "../components/Home/EmailPassword";
import Personal from "../components/Home/Personal";
import BasicCard from "../components/Card/Basic";
import OpenCard from "../components/Card/Open";
import { SelectVehicle } from "../components/SelectVehicle";
import SlideIn from "../components/Alerts/SlideIn";
import SlideInVehicles from "../components/Alerts/SlideInOptions";
import SlideInGuarantor from "../components/Alerts/SlideInGuarantor";
import SlideInPermits from "../components/Alerts/SlideInPermits";
import SlideInOptions from "../components/Alerts/SlideInOptions";
import { Link, useNavigate } from "react-router-dom";

const DISPATCH_METHODS = [
  "all",
  "walking",
  "bicycle",
  "big car",
  "car",
  "truck",
];
const PERMIT_METHODS = ["bicycle", "big car", "car", "truck"];

function Documents({
  user,
  authenticated,
  loading,
  setLoading,
  setMessage,
  inDashboard,
}) {

    const navigate = useNavigate()

  const [selected, setSelected] = useState("all");
  const [vehicles, setVehicles] = useState([]);
  const [open, setOpen] = useState(false);
  const [openG, setOpenG] = useState(false);
  const [openPermit, setOpenPermit] = useState(false);
  const [next, setNext] = useState("vehicle");
  const [validated, setValidated] = useState(false);
  const [hasID, setHasID] = useState(false);
  const [hasGuarantor, setHasGuarantor] = useState(false);

  useEffect(() => {
    if (!!hasGuarantor && !!hasID) {
        navigate("/dashboard")
    }
  }, [hasID, hasGuarantor]);

  useEffect(() => {
    setHasID(false);
    setHasGuarantor(false);

    // localStorage.setItem("@idcard", false);
    const hasID = JSON.parse(localStorage.getItem("@idcard"));
    if (!!hasID) {
      setHasID(true);
    }

    const hasGuarantor = localStorage.getItem("@guarantor");
    if (!!hasGuarantor) {
      setHasGuarantor(true);
    }
  }, [open]);

  useEffect(() => {
    setValidated(false);
    if (vehicles.length > 0 && next == "vehicle") {
      setValidated(true);
    }
    if (next == "documents") {
      setValidated(true);
    }
  }, [vehicles]);

  const addVehicleToList = (vehicle) => {
    if (!vehicles.includes(vehicle))
      if (vehicle == "all") {
        setVehicles(DISPATCH_METHODS);
      } else {
        setVehicles([...vehicles, vehicle]);
      }
    else {
      if (vehicle == "all") {
        setVehicles([]);
      } else {
        const new_methods = vehicles.filter((method) => method != vehicle);
        if (new_methods[0] == "all") new_methods.shift();
        setVehicles(new_methods);
      }
    }
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex self-start">
          <div className="">
            <h2 className="mt-6 text-xl tracking-tight font-bold text-slate-700">
              Hi {user?.firstname} !
            </h2>
            <p className="mt-2 text-sm text-slate-400">
              Your dispatcher account is almost ready.
            </p>
            <p className="mt-2 text-sm text-slate-500">
              Kindly update the following to proceed to your dashboard.
            </p>
            <p className="mt-5 text-sm text-slate-600">
              { !hasID ? "Start with \"Type of Vehicle\"." : "Finally, add details of your Guarantor." }
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-8">
          {(!hasID || !!inDashboard) && (
            <BasicCard
              title={"Type of Vehicle"}
              Icon={TwoWheelerOutlinedIcon}
              bgColor={"#FD9A27"}
              color={"#FFFFFF"}
              setOpen={setOpen}
              user={user}
              openable={true}

            />
          )}

          {(!hasGuarantor || !!inDashboard) && (
            <BasicCard
              title={"Guarantor Info"}
              Icon={GroupAddOutlinedIcon}
              bgColor={"#323643"}
              setOpen={setOpenG}
              user={user}
              openable={hasID}
            />
          )}
          
        </div>

        <SlideInOptions
          open={open}
          setOpen={setOpen}
          vehicles={vehicles}
          addVehicleToList={addVehicleToList}
          next={next}
          setNext={setNext}
          validated={validated}
          setValidated={setValidated}
          user={user}
          setLoading={setLoading}
          loading={loading}
          setMessage={setMessage}
        />
        <SlideInGuarantor
          open={openG}
          setOpen={setOpenG}
          user={user}
          next={"guarantor"}
          setLoading={setLoading}
          loading={loading}
          setHMessage={setMessage}
        />
      </div>
    </div>
  );
}

export default Documents;
