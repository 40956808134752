import React, { useState, forwardRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import SlideIn from "./SlideIn";
import { SelectVehicle } from "../SelectVehicle";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import config from "../../config";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DOCUMENTS = {
  name: "",
  uploads: "",
};

const DATA = {
  methods: {},
  id_card: [],
  permit: [],
};

export default function SlideInOptions({
  setOpen,
  open,
  next,
  setNext,
  vehicles,
  validated,
  setValidated,
  addVehicleToList,
  user,
  setLoading,
  loading,
  setMessage,
}) {
  const [selectedID, setSelectedID] = useState(DOCUMENTS);
  const [selectedPermit, setSelectedPermit] = useState(DOCUMENTS);
  const [documents, setDocuments] = useState(DATA);
  const [idValidated, setIdValidated] = useState(false);
  const [permitValidated, setPermitValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
    if (next == "idcard" && !!selectedID.name && !!selectedID.uploads) {
      setValidated(true);
      setIdValidated(true);
    }
  }, [selectedID, next]);

  useEffect(() => {
    setValidated(false);
    if (next == "permit" && !!selectedPermit.name && !!selectedPermit.uploads) {
      setValidated(true);
      setPermitValidated(true);
    }
  }, [next, selectedPermit]);

  useEffect(() => {
    if (next == "documents") {
      if (!!validated && !!idValidated && !!permitValidated) {
        setValidated(true);
        const data = {
          methods: vehicles,
          id_card: selectedID,
          permit: selectedPermit,
        };
        setDocuments(data);
      } else setValidated(false);
    }
  }, [next]);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleIDChange = (e) => {
    getBase64(e.target.files[0], (result) => {
      setSelectedID({ ...selectedID, uploads: [result] });
    });
  };

  const handlePermitChange = (e) => {
    getBase64(e.target.files[0], (result) => {
      setSelectedPermit({ ...selectedPermit, uploads: [result] });
    });
  };

  const uploadDocuments = async () => {
    const user = await JSON.parse(localStorage.getItem("@user"));
  
    console.log("documents user >>", user);
    // setOpen(false)
    setLoading(true);

    fetch(`${config.URL}driver/update_method`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user?.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user?._id,
        data: documents,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!!responseJson.status) {
          setMessage({ status: "success", text: "Documents uplaoded successfully" });
          localStorage.setItem("@idcard", true);
          setOpen(false);
          window.location.reload()

        } else {
          setMessage({ status: "error", text: responseJson.msg });
        }
      })
      .catch((error) => {
        setMessage({ status: "error", text: "Network Unavailabe!" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SlideIn
      open={open}
      setOpen={setOpen}
      title={
        (next === "vehicle" && "Select your vehicle type(s)") ||
        (next === "documents" && "Upload Documents") ||
        (next === "permit" && "Upload Permit") ||
        (next === "idcard" && "Upload ID Card")
      }
      next={next}
      setNext={setNext}
      validated={validated}
      uploadDocuments={uploadDocuments}
      loading={loading}
    >
      {next === "vehicle" && (
        <div className="flex flex-col justify-center gap-4 h-full">
          <div className="flex flex-row justify-evenly items-center">
            <SelectVehicle
              isSelected={vehicles.includes("all")}
              addVehicleToList={addVehicleToList}
              vehicle="all"
            />
            <SelectVehicle
              isSelected={vehicles.includes("walking")}
              addVehicleToList={addVehicleToList}
              vehicle="walking"
            />
            <SelectVehicle
              isSelected={vehicles.includes("bicycle")}
              addVehicleToList={addVehicleToList}
              vehicle="bicycle"
            />
          </div>
          <div className="flex flex-row justify-evenly items-center">
            <SelectVehicle
              isSelected={vehicles.includes("big car")}
              addVehicleToList={addVehicleToList}
              vehicle="big car"
            />
            <SelectVehicle
              isSelected={vehicles.includes("car")}
              addVehicleToList={addVehicleToList}
              vehicle="car"
            />
            <SelectVehicle
              isSelected={vehicles.includes("truck")}
              addVehicleToList={addVehicleToList}
              vehicle="truck"
            />
          </div>
        </div>
      )}

      {next === "documents" && (
        <div className="flex flex-col justify-center gap-6 h-full">
          <div className="flex flex-col justify-evenly items-center gap-3">
            <Chip
              style={{ minWidth: 150, minHeight: 80 }}
              color={"warning"}
              clickable
              onClick={() => setNext("idcard")}
              variant={"outlined"}
              icon={<BadgeOutlinedIcon />}
              label="ID card"
              variant={!idValidated ? "outlined" : ""}
            />
            <Chip
              style={{ minWidth: 150, minHeight: 80 }}
              color={"warning"}
              clickable
              onClick={() => setNext("permit")}
              variant={"outlined"}
              icon={<ReceiptLongOutlinedIcon />}
              label="Permit"
              variant={!permitValidated ? "outlined" : ""}
            />
          </div>
        </div>
      )}

      {next === "idcard" && (
        <div className="flex flex-col justify-center gap-6 h-full">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select ID Card
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedID.name}
              label="Select ID Card"
              onChange={(e) =>
                setSelectedID({ ...selectedID, name: e.target.value })
              }
            >
              <MenuItem value={"national_id"}>National ID</MenuItem>
              <MenuItem value={"intl_passport"}>
                International Passport
              </MenuItem>
              <MenuItem value={"voters_card"}>Voters Card</MenuItem>
            </Select>
          </FormControl>
          <form>
            <input type="file" name="fileUpload" onChange={handleIDChange} />
          </form>
        </div>
      )}

      {next === "permit" && (
        <div className="flex flex-col justify-center gap-6 h-full">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select a Permit
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedPermit.name}
              label="Select a Permit"
              onChange={(e) =>
                setSelectedPermit({ ...selectedPermit, name: e.target.value })
              }
            >
              <MenuItem value={"riders_permit"}>Riders' Permit</MenuItem>
              <MenuItem value={"drivers_licence"}>Drivers' Licence</MenuItem>
            </Select>
          </FormControl>
          <form>
            <input
              type="file"
              name="fileUpload"
              onChange={handlePermitChange}
            />
          </form>
        </div>
      )}
    </SlideIn>
  );
}
