import React, { useEffect, useState } from "react";
import { Card, Chip, Divider } from "@mui/material";
import { DeviceMobileIcon, LockClosedIcon } from "@heroicons/react/solid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import TwoWheelerOutlinedIcon from "@mui/icons-material/TwoWheelerOutlined";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import "../App";

import logo from "../logo.svg";
import Header from "../components/Header";
import EmailPassword from "../components/Home/EmailPassword";
import Personal from "../components/Home/Personal";
import BasicCard from "../components/Card/Basic";
import OpenCard from "../components/Card/Open";
import { SelectVehicle } from "../components/SelectVehicle";
import SlideIn from "../components/Alerts/SlideIn";
import SlideInVehicles from "../components/Alerts/SlideInOptions";
import SlideInGuarantor from "../components/Alerts/SlideInGuarantor";
import SlideInPermits from "../components/Alerts/SlideInPermits";
import SlideInOptions from "../components/Alerts/SlideInOptions";
import { Link, useNavigate } from "react-router-dom";

const DISPATCH_METHODS = [
  "all",
  "walking",
  "bicycle",
  "big car",
  "car",
  "truck",
];
const PERMIT_METHODS = ["bicycle", "big car", "car", "truck"];

function AuthDocuments({
  user,
  authenticated,
  loading,
  setLoading,
  setMessage,
  inDashboard,
}) {
  const navigate = useNavigate();

  const [selected, setSelected] = useState("all");
  const [vehicles, setVehicles] = useState([]);
  const [open, setOpen] = useState(false);
  const [openG, setOpenG] = useState(false);
  const [openPermit, setOpenPermit] = useState(false);
  const [next, setNext] = useState("vehicle");
  const [validated, setValidated] = useState(false);
  const [hasID, setHasID] = useState(false);
  const [hasGuarantor, setHasGuarantor] = useState(false);
  const [value, setValue] = React.useState("1");

  const selectedVehicles = user?.driver_info?.dispatch_methods ?? [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setHasID(false);
    setHasGuarantor(false);

    // localStorage.setItem("@idcard", false);
    const hasID = JSON.parse(localStorage.getItem("@idcard"));
    if (!!hasID) {
      setHasID(true);
    }

    const hasGuarantor = JSON.parse(localStorage.getItem("@guarantor"));
    if (!!hasGuarantor) {
      setHasGuarantor(true);
    }
  }, [open]);

  useEffect(() => {
    setValidated(false);
    if (vehicles.length > 0 && next == "vehicle") {
      setValidated(true);
    }
    if (next == "documents") {
      setValidated(true);
    }
  }, [vehicles]);

  const addVehicleToList = (vehicle) => {
    // if (!vehicles.includes(vehicle))
    //   if (vehicle == "all") {
    //     setVehicles(DISPATCH_METHODS);
    //   } else {
    //     setVehicles([...vehicles, vehicle]);
    //   }
    // else {
    //   if (vehicle == "all") {
    //     setVehicles([]);
    //   } else {
    //     const new_methods = vehicles.filter((method) => method != vehicle);
    //     if (new_methods[0] == "all") new_methods.shift();
    //     setVehicles(new_methods);
    //   }
    // }
  };

  const getDocName = (name) => {
    switch (name) {
      case "intl_passport":
        return "International Passport";

      case "drivers_licence":
        return "Drivers Licence";

      case "national_id":
        return "National ID Card";

      case "voters_card":
        return "Voters Card";

      case "riders_permit":
        return "Riders Permit";
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Header user={user} />
      <div className="min-h-full w-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg w-full space-y-8">
          <div className="flex self-start">
            <div className="">
              <h2 className="mt-6 text-xl tracking-tight font-bold text-slate-700">
                Hi {user?.firstname} !{" "}
                {!!authenticated && (
                  <span
                    onClick={logout}
                    className="group relative py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Logout
                  </span>
                )}
              </h2>
              <p className="mt-2 text-sm text-slate-400">
                  {
                      !!user?.driver_info?.verified ? "Your dispatcher account is has been verified" : "Your dispatcher account is awaiting verification"
                  }
              </p>
              {/* <p className="mt-2 text-sm text-slate-500">
              Kindly update the following to proceed to your dashboard.
            </p> */}
            </div>
          </div>
          <div className="w-full">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="My Profile" value="1" />
                    <Tab label="Vehicle" value="2" />
                    <Tab label="Guarantor" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Full name"
                        secondary={`${user?.firstname} ${user?.lastname}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Email" secondary={user?.email} />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <PhoneIphoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Phone" secondary={user?.tel} />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <MilitaryTechIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Status"
                        secondary={
                          !!user?.driver_info?.verified ? "Verified" : "Pending"
                        }
                      />
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel value="2">
                  {selectedVehicles.length > 0 ? (
                    <>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <BadgeOutlinedIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="ID Card"
                            secondary={getDocName(user?.driver_info?.id_card?.name)}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <ReceiptLongOutlinedIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Permit"
                            secondary={getDocName(user?.driver_info?.permit?.name)}
                          />
                        </ListItem>
                      </List>
                      <div className="my-5" />
                      <Divider  />
                      <div className="my-3" />
                      <p className="py-5 text-xs" >*Your vehicles are highlighted</p>
                      <div className="flex flex-col justify-center gap-4 h-full">
                        <div className="flex flex-row flex-wrap justify-between items-center gap-3">
                          <SelectVehicle
                            isSelected={selectedVehicles?.includes("walking")}
                            addVehicleToList={addVehicleToList}
                            vehicle="walking"
                          />
                          <SelectVehicle
                            isSelected={selectedVehicles?.includes("bicycle")}
                            addVehicleToList={addVehicleToList}
                            vehicle="bicycle"
                          />
                        {/* </div> */}
                        {/* <div className="flex flex-row justify-between items-center"> */}
                          <SelectVehicle
                            isSelected={selectedVehicles?.includes("big car")}
                            addVehicleToList={addVehicleToList}
                            vehicle="big car"
                          />
                          <SelectVehicle
                            isSelected={selectedVehicles?.includes("car")}
                            addVehicleToList={addVehicleToList}
                            vehicle="car"
                          />
                          <SelectVehicle
                            isSelected={selectedVehicles?.includes("truck")}
                            addVehicleToList={addVehicleToList}
                            vehicle="truck"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>No vehicle method added yet</p>
                  )}
                </TabPanel>
                <TabPanel value="3">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Full name"
                        secondary={`${
                          user?.driver_info?.guarantor_info
                            ?.guarantor_firstname ?? "No firstname"
                        } ${
                          user?.driver_info?.guarantor_info
                            ?.guarantor_lastname ?? "No lastname"
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Email"
                        secondary={
                          user?.driver_info?.guarantor_info?.guarantor_email ??
                          "None yet"
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <PhoneIphoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Phone"
                        secondary={
                          user?.driver_info?.guarantor_info?.guarantor_phone ??
                          "None yet"
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <MilitaryTechIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Work"
                        secondary={
                          user?.driver_info?.guarantor_info?.guarantor_work ??
                          "None yet"
                        }
                      />
                    </ListItem>
                  </List>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthDocuments;
